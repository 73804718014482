import { legacy_createStore as createStore } from 'redux';
import { combineReducers } from 'redux'
import GeneralReducer from './reducer/generalreducer'
import { persistReducer, createMigrate, persistStore  } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'

import { configureStore } from "@reduxjs/toolkit";
import { migrations } from './migration'
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage'

const persistConfig = {
	key: 'root',
	version: 1,
	// whitelist:[]
	storage,
	migrate: createMigrate(migrations, { debug: false }),
}

const persistedReducer = persistReducer(
	persistConfig,
	combineReducers({ general: GeneralReducer })
)		
// let store = createStore(persistedReducer, {})

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk]
})

export const persistor = persistStore(store)

// store.subscribe(() => {
// 	console.log(store.getState())
// })

// export const persistor = persistStore(store)
